import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import GlobalStyle from '../styles/GlobalStyle';
import {Container} from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import clearLogo from '../assets/images/clear-logo.png';

let pageTitle = 'Privacy Policy | Libretto';
let metaTags = [
	{name: 'description', content: 'Libretto Privacy Policy'},
	{name: 'keywords', content: 'Privacy Policy, Your Privacy is Important to Us'},
	{property: 'og:title', pageTitle},
	{property: 'og:image', content: clearLogo},
	{property: 'twitter:image', content: clearLogo}
];

/* eslint-disable max-len */
const PrivacyPolicy = () =>
	<Fragment>
		<Helmet
			title={pageTitle}
			meta={metaTags}
		/>
		<GlobalStyle />
		<Header generic />
		<Container>
			<h2>Privacy Policy</h2>
			<h3>Updated: April 23, 2024</h3>
			<p>Libretto LLC (“Libretto”) is committed to safeguarding the use of your personal information that is made available to us as your internet investment adviser. Libretto and its affiliates (referred to as “we”, “our” and “us”) protect the security and confidentiality of the personal information we have and make efforts to ensure that such information is used for proper business purposes in connection with the management or servicing of your accounts.</p>
			<p>Our relationship with you is our most important asset. We understand that you have entrusted us with your private information, and we do everything we can to maintain that trust. We do not sell your non-public personal information to anyone, nor does Libretto provide such information to others except for discrete and proper business purposes in connection with the servicing and management of your account and providing the services as discussed below. Details of our approach to privacy and how your personal non-public information is collected and used are set forth in this privacy policy (“Privacy Policy”). Please read this Privacy Policy carefully to understand what we do. </p>
			<p>This Privacy Policy does not address the privacy practices of any third parties that we do not own, control, or are affiliated with. If you deal with us through an independent Registered Investment Adviser, please also review your advisor&apos;s related privacy policy.</p>
			<p>By using libretto.io (the “Website”), you agree to the terms of this Privacy Policy.</p>
			<h3>Privacy Policy Background</h3>
			<p>Libretto LLC is a U.S. Securities and Exchange Commission (“SEC”) registered internet investment advisor and has developed this Privacy Policy as required by SEC Regulation S-P and the U.S. Gramm-Leach-Bliley Act of 1999. Regulation S-P and the U.S. Gramm-Leach-Bliley Act of 1999 require Libretto to implement policies and procedures to protect the non-public personal information of customers that Libretto collects in the normal course of conducting its business, and that Libretto shall provide a notice to such persons that describes our Privacy Policy. </p>

			<h3>Collection of Personal Information</h3>
			<p>We may collect non-public information about you (“Customer Information”) from the following sources:</p>
			<ul>
				<li>certain personal information (such as your name and email address) that you may provide in order for us to grant login credentials and save your profile and the data associated with it;</li>
				<li>information we receive in the course of establishing a customer relationship including, but not limited to, information and data provided in applications, forms, questionnaires and through our Website; and</li>
				<li>information delivered to Libretto via email, information request through our Website or other communication such as postal mail or fax.</li>
			</ul>
			<p>Additionally, we collect personal information related to the clients of Libretto customers that is input by Libretto customers (i.e. paid subscribers) when they use the Libretto system to create strategies for their respective clients (“Client Information”). Client Information includes, but is not limited to, the following:</p>
			<ul>
				<li>Name and age</li>
				<li>Contact information, such as email address</li>
				<li>Assets and liabilities</li>
				<li>Occupation and income</li>
				<li>Personal objectives or priorities</li>
				<li>Account balances</li>
				<li>Last 4 digits of account numbers</li>
				<li>Location of assets and accounts</li>
			</ul>
			<p>Unless otherwise prohibited by applicable law, Libretto may use and access Client Information as necessary to provide its services.</p>

			<h3>Automated Collection of Information</h3>
			<p>We may collect information about you automatically as you access the Website (“Usage Information”). Usage Information includes, but is not limited to, the following:</p>
			<ul>
				<li>anonymous analytics data used to monitor traffic and fix bugs, which includes Google Analytics, traffic analysis, event metrics and the collection of obfuscated error tracking data. For example, we collect information on web requests, the data sent in response to such requests, the Internet Protocol address, the browser type, the browser language and a timestamp for the request. You may choose to opt out of Libretto&apos;s automated tracking for user metrics by enabling the &#34;Do Not Track&#34; setting in your browser or contacting your designated support channel;</li>
				<li>data related to access and security of Libretto customer accounts, including information required for authentication logic, Internet Protocol address, request timestamps, and success/failure rates;</li>
				<li>internal logs monitoring customer actions taken while using the Libretto system, such as the use of features and modifications to data; and</li>
				<li>cookies used to store session information. Libretto uses cookies to track usage of the Website and to address security issues. We may also use cookies to store your preferences relating to use of our Website. If you do not want to allow our Website to place a cookie on your computer, you can change your browser&apos;s settings to deny third-party cookies; however, denying first-party cookies will prevent you from using the Website.</li>
			</ul>
			<p>You may view and change the Customer Information associated with your account. You may contact us to ask to have your account and the Customer Information associated with it deleted or removed. Client Information may not be deleted without approval of a customer firm. Some information, such as Usage Information, may not be deleted. In addition, it may be impossible to completely delete Customer Information or Client Information due to data backups, security measures and/or compliance archiving.</p>

			<h3>How We Use Your Information</h3>
			<p>Libretto may use Customer Information, Client Information and Usage Information (collectively, “User Information”) for business purposes, including, but not limited to, the following:</p>
			<ul>
				<li>to provide you with the Libretto services, including to identify you when you sign in to your account, to respond to your inquiries, to communicate with you about the services or the Website; and for billing and collection purposes;</li>
				<li>to, upon your request, inform your clients about opportunities related to the services, such as informational webinars;</li>
				<li>for planning and strategy development and other business purposes related to our services, including to enhance and improve our Website and to analyze the use of the Website and our services; </li>
				<li>to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our policies, or as otherwise required by law;</li>
				<li>for any other purposes disclosed at the time the information is collected or to which you consent; and</li>
				<li>as otherwise specifically described in this Privacy Policy.</li>
			</ul>

			<h3>Information About You That Libretto Shares</h3>
			<p>Libretto works as an organization to provide services that benefit our customers. We restrict access to your User Information to employees and agents who assist in providing the services for which you have hired Libretto. We do not sell your User Information to anyone.</p>
			<p>We may share User Information with third parties, including, but not limited to:</p>
			<ul>
				<li>our affiliates for proper business purposes in connection with the management or servicing of your accounts and as permitted or required by law;</li>
				<li>non-affiliated third parties as necessary for us to provide agreed services to you consistent with applicable law;</li>
				<li>institutions with whom we have joint business arrangements for proper business purposes in connection with the management or servicing of your account;</li>
				<li>persons we believe to be your authorized agent or representative or regulators in order to satisfy Libretto’s regulatory obligations and as otherwise required or permitted by law;</li>
				<li>companies we hire to help administer our business, provided that such companies may use your User Information only in the performance of the specific services we have requested, may not use your User Information for their own purposes and are contractually obligated to maintain strict confidentiality; and</li>
				<li>when required by law, court order, or other government or law enforcement authority or regulatory agency.</li>
			</ul>
			<p>You have the right to opt out of the sharing of your User Information to non-affiliated third parties and may do so via your designated support channel or by email at info@libretto.io. Please note that this may affect your experience with certain features of our Website.</p>
			<p>If we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, User Information would be one of the assets that may be transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer of us or our assets may continue to use your User Information as set forth in this Privacy Policy.</p>

			<h3>Aggregated Data</h3>
			<p>While Libretto protects your User Information, we may use and commercialize aggregated User Information (“Aggregated Data”) for purposes of, but not limited to, data analytics, research and studies, provided that such Aggregated Data is used solely on a de-identified basis. Such Aggregated Data is anonymized and is not considered personal information or User Information under this Privacy Policy.</p>
			<h3>Information About Former Customers</h3>
			<p>Libretto does not disclose, and does not intend to disclose, User Information to non­affiliated third parties with respect to persons who are no longer our customers.</p>
			<h3>Confidentiality and Security</h3>
			<p>Our employees are advised about the firm&apos;s need to protect and respect the confidentiality of our customers&apos; User Information. Additionally, we maintain physical, procedural, legal and electronic safeguards in an effort to protect User Information from access by unauthorized parties. Our practices include, but are not limited to, cybersecurity risk assessments (analysis of internal systems and vendors, penetration testing, etc.), user security and access controls (multifactor authentication, least privilege access, secure devices, etc.), information protection (encryption, system monitoring, intrusion detection and response, runtime defense, etc.), threat and vulnerability management (continuous analysis and monitoring, defect management, etc.) and cybersecurity incident response and recovery (backups, response and recovery plans, etc.).</p>
			<h3>Children</h3>
			<p>Minors and children should not use Libretto. By using the Website, you represent that you have the legal capacity to enter into a binding agreement.</p>
			<h3>California Residents</h3>
			<p>For accounts with a California mailing address, we will not share your User Information with a financial company for joint marketing purposes except as required or permitted by law.</p>
			<h3>International Visitors</h3>
			<p>Libretto is operated from the United States. If you are visiting the Website from outside the U.S., you agree to any processing of any User Information you provide us according to this policy.</p>
			<h3>We Will Keep You Informed</h3>
			<p>We will send you notice of our Privacy Policy annually for as long as you maintain an ongoing relationship with us. Periodically we may revise our Privacy Policy, and we will provide you with a revised Privacy Policy if the changes materially alter the previous Privacy Policy. We will not, however, revise our Privacy Policy to permit the sharing of User Information other than as described in this notice unless we first notify you and provide you with an opportunity to prevent the information sharing. At all times, you may request our current Privacy Policy by contacting Libretto by email at info@libretto.io.</p>
		</Container>
		<Footer />
	</Fragment >;
/* eslint-enable max-len */

export default PrivacyPolicy;
